<template>
  <div class="file-iframe-wrap" style="flex-direction: column">
    <MainButton
        style="height: 140px; max-width: 100%; letter-spacing: 1px;"
        v-if="isAdmin && printBtn" @click.native="print"
        :value="'PRINT'"
    />
    <template v-if="!isMobileFunc()">
      <iframe v-if="fileBase64 && !loadingFile" name="printf" id="file-iframe" class="file-iframe" width='100%' height='500' :src="fileBase64"></iframe>
    </template>

    <div v-if="isMobileFunc()">
      <div class="download-mobile-file">
        {{$t('common_downloadMobileFile.localization_value.value')}}
      </div>
    </div>

    <NotFoundModule class="error-component" v-else-if="fileBase64 === false && loadingFile"/>
  </div>
</template>

<script>
  import NotFoundModule from "../../../NotFoundModule/NotFoundModule";
  import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";

  export default {
    name: "GetPdfFile",

    components: {
      MainButton,
      NotFoundModule
    },

    mixins: [mixinDetictingMobile],

    data() {
      return {
        loadingFile: true,
        printBtn: false,
        fileBase64: null,
        dataBase64: '',
      }
    },

    beforeCreate() {
      document.title = this.$t('common_getFileTitle.localization_value.value');
    },

    mounted() {

      if(this.$route.query.print) {
        this.printBtn = true
      }
      console.log(this.$route.params);
      if(
          this.$route.query?.orderFEDEX ||
          this.$route.query?.orderDHL ||
          this.$route.query?.orderTNT
      ) {
        this.$store.dispatch('disableExpressLabel', this.$route.params.id)
      }

      this.getFile(this.$route.params.id)
    },

    methods: {
      print() {
        window.frames["printf"].focus();
        window.frames["printf"].print();


        // let options = {
        //   // changes the value of 'this' in the success, error, timeout and complete
        //   // handlers. The default value of 'this' is the instance of the PrintNodeApi
        //   // object used to make the api call
        //   context: null,
        //   // called if the api call was a 2xx success
        //   success: function () {},
        //   // called if the api call failed in any way
        //   error: function () {},
        //   // called afer the api call has completed after success or error callback
        //   complete: function (response) {
        //     console.log(
        //         "%d %s %s returned %db in %dms",
        //         response.xhr.status,
        //         response.reqMethod,
        //         response.reqUrl,
        //         response.xhr.responseText.length,
        //         response.getDuration()
        //     );
        //   },
        //   // called if the api call timed out
        //   timeout: function (url, duration) {
        //     console.log("timeout", url, duration)
        //   },
        //   // the timeout duration in ms
        //   timeoutDuration: 3000
        // };
        //
        // let api = new window.PrintNode.HTTP(
        //     new window.PrintNode.HTTP.ApiKey('_5ZFH8uJUrrJmfi1hYJ-PXvgOomRTY4olCBG3W6HYz8'),
        //     options
        // );
        //
        // var printJobPayload = {
        //   "printerId": 72492266,
        //   "title": "test printjob",
        //   "contentType": "pdf_base64",
        //   "content": this.dataBase64,
        //   "source": "javascript api client"
        // }
        // api.createPrintjob(options, printJobPayload);

      },

      getFile(id) {
        this.$store.dispatch('getGetFileEntries', id).then((response) => {

          if (!this._.has(response, 'data')) {
            this.loadingFile = false
            this.fileBase64 = false
            return
          }

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */

            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              let data = this.getRespData(response)

              this.dataBase64 = data['fileContentBase64']


              let byteCharacters = atob(data['fileContentBase64'])
              let byteNumbers = new Array(byteCharacters.length)
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
              }
              let byteArray = new Uint8Array(byteNumbers)
              let file = new Blob([byteArray], { type: 'application/pdf;base64' })

              this.fileBase64 = URL.createObjectURL(file)

              if (this.isMobileFunc()){
                let a = document.createElement("a")
                a.style = "display: none";
                a.href = this.fileBase64;
                a.download = 'file.pdf';
                a.click();
              }


              // document.addEventListener("keydown", (event) => {
              //   console.log(444);
              //   if (event.isComposing || event.keyCode === 80) {
              //     this.print()
              //   }
              //   // do something
              // });


              this.loadingFile = false


              break
            }
          }


        })
      },
    },

  }
</script>

<style scoped lang="scss">

  .file-iframe-wrap{
    display: flex;
  }

  .file-iframe{
    border: 0;
    width: 100%;
    height: 100vh;
  }

  .error-component{
    margin: 0 auto;
  }

  .download-mobile-file{
    padding-top: 15%;
    padding-right: 15px;
    padding-left: 15px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: #f8f4ee;
    font-family: Roboto,sans-serif;
    font-weight: 600;
    color: #232323;
    text-align: center;
    overflow: hidden;
  }
</style>
