import {checkValid} from "../../../globalModels/functions/ModelsValidate";
// let _ = require('lodash');

export function HSCode() {

  this.checkValid = checkValid

  this.data = {

    id: '',
    category: '',
    name: '',
    material: '',
    code: '',
    codeUSA: '',
    countryGroupId: 0,
}

  this.validation = {
    category: false,
    name: false,
    material: false,
    code: false,
    codeUSA: false,
  }

  this.validationTranslate = {
    category: '',
    name: '',
    material: '',
    code: '',
    codeUSA: '',
  }

  this.validationTxt = {
    category: false,
    name: false,
    material: false,
    code: false,
    codeUSA: false,
  }

}


/*****************   SETTERS   ******************/

/***
 * Pseudo Required Fields start
 */

HSCode.prototype.setId = function (val) {
  this.data.id = val
}

/*** **/


HSCode.prototype.setCategory = function (val) {
  this.data.category = val
  this.setName('')
  this.setMaterial('')
}

HSCode.prototype.setName = function (val) {
  this.setMaterial('')
  this.data.name = val
}

HSCode.prototype.setMaterial = function (val) {
  this.data.material = val
}

HSCode.prototype.setCode = function (val) {
  this.data.code = val
}

HSCode.prototype.setCodeUSA = function (val) {
  this.data.codeUSA = val
}

HSCode.prototype.setCountryGroupId = function (val) {
  this.data.countryGroupId = val
}

HSCode.prototype.setHSCodeItem = function (val) {
  this.setCategory(val.proform_category)
  this.setName({proform_value: val.proform_name})
  this.setMaterial({proform_value: val.proform_material})
  this.setCode(val.code)
  this.setCountryGroupId(val.country_group_id)
}




/*****************   GETTERS   ******************/

/***
 * Pseudo Required Fields start
 */

HSCode.prototype.getId = function () {
  return this.data.id
}

/*** ***/

HSCode.prototype.getCategory = function () {
  return this.data.category
}

HSCode.prototype.getName = function () {
  return this.data.name
}

HSCode.prototype.getMaterial = function () {
  return this.data.material
}

HSCode.prototype.getCode = function () {
  return this.data.code
}

HSCode.prototype.getCodeUSA = function () {
  return this.data.codeUSA
}

/*******************   FUNCTIONS   *************/



/******************  VALIDATIONS  *****************/


HSCode.prototype.firstValidation = function (
  {
    codeUSA = false
  }
) {

  let data = this.data

  let validationItems = {
    category: data.category,
    name: data.name,
    material: data.material,
    code: data.code,
    codeUSA: data.codeUSA,
  }

  let validationOptions = {
    category: {type: ['empty']},
    name: {type: ['empty']},
    material: {type: ['empty']},
    code: {type: ['empty']},
    codeUSA: {type: codeUSA ? ['empty'] : []},
  }

  return (this.checkValid(validationItems, validationOptions))

}

/******************  PREPARE DATA  *****************/


HSCode.prototype.prepareData = function(edit = false) {

  let data = {
    'proform_category_id' : this.data.category.id,
    'proform_name_id' : this.data.name["proform_value"].id,
  }

  if(!edit) {
    data['code_europe'] = this.data.code
    data['code_usa'] = this.data.codeUSA
    data['proform_material_ids'] = this.data.material.map(item => {return item["proform_value"].id})
  } else {
    data['proform_material_id'] = this.data.material["proform_value"].id
    data['code'] = this.data.code
  }

  return data
}